@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
:root {
  --background-blue: #f2f8fd;
  --accent-blue: #3468f0;
  --all-transition: all ease-in-out 0.25s;
  --border-colors: #3468f030;
}
* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: #696969;
}
body {
  font-family: "Space Grotesk", sans-serif;
  background: #fff;
  scroll-behavior: smooth;
  color: #696969;
}
.warp_container {
  width: 95%;
  margin: auto;
}
body {
  position: relative;
}
nav {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  z-index: 10000;
  filter: drop-shadow(7px 13px 17px #00000014);
  z-index: 1000;
}
.main_nav {
  display: flex;
  align-items: center;
  /* background: red; */
  justify-content: space-between;
  padding: 27px;
}
.menu_nav ul {
  display: flex;
  list-style: none;
  gap: 30px;
}
.menu_nav ul li a {
  color: #030014;
  font-size: 20px;
  font-weight: 600;
  transition: var(--all-transition);
  text-decoration: none;
}
.menu_nav ul li a:hover {
  color: #3468f0;
}
.active_a {
  color: #3468f0 !important;
}
.logo {
  width: 100px;
  font-size: 30px;
  color: black;
  font-weight: bold;
}
.nav_right{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}
.menu_btn,
.menu_btn_mobile {
  cursor: pointer;
  background: var(--accent-blue);
  color: white !important;
  display: flex;
  font-weight: 600;
  padding: 15px 27px;
  border-radius: 15px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  transition: var(--all-transition);
  border: none;
  outline: none;
  width: fit-content;
}
.menu_btn a,
.menu_btn_mobile a {
  color: white;
  text-decoration: none;
}
.menu_btn:hover,
.menu_btn_mobile:hover {
  background: black;
}
.form_btn {
  cursor: pointer;
  background: var(--accent-blue);
  color: white !important;
  display: flex;
  font-weight: 600;
  padding: 15px 27px;
  border-radius: 15px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  transition: var(--all-transition);
  border: none;
  outline: none;
  width: fit-content;
}
.form_btn a {
  color: white;
  text-decoration: none;
}
.form_btn:hover{
  background: black;
}
.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
}
.menu_nav {
  transition: var(--all-transition);
}
.menu_nav.open {
  display: flex;
}
.menu_btn_mobile {
  display: none;
  margin-top: 20px;
}
.header {
  max-height: 110vh;
  height: 110vh;
  background: var(--background-blue);
  background-repeat: no-repeat;
  background-image: url(./Assets/Bg-3-12.webp);
  background-position: bottom center;
  display: flex;
  overflow: hidden;
  /* align-items: center; */
  /* justify-content: center; */
  /* position: relative; */
}
.container {
  width: 85%;
  margin: 0 auto;
}
.header_flex {
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* gap: 10px; */
}
.header_text {
  margin-top: 220px;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  width: 52%;
}
.text_heading {
  font-size: 65px;
  margin-top: 30px;
  color: black;
  font-weight: 700;
  line-height: 1.1em;
}
.italc_heading {
  font-size: 65px;
  color: var(--accent-blue);
  font-weight: 600;
  font-family: "Playfair Display", serif;
  font-style: italic;
}
.heading_p {
  font-size: 20px;
  margin-top: 10px;
  line-height: 1.9em;
}
.prompt_warp {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 20px;
  margin-top: 32px;
}
.input_prompt {
  display: flex;
  background: white;
  width: 80%;
  padding: 10px;
  justify-content: space-between;
  border-radius: 10px;
  filter: drop-shadow(7px 13px 17px #0000000f);
  align-items: center;
  /* margin-top: 32px; */
}
.input_prompt .menu_btn {
  font-size: 18px;
  font-size: 300 !important;
  padding: 15px 27px;
}
.input_prompt input {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: black !important;
}

.prompt_box {
  filter: drop-shadow(7px 13px 17px #0000000f);
  background: white;
  height: 71px;
  width: 71px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 23.5px;
  padding-left: 23.5px;
  border-radius: 14px;
  transition: var(--all-transition);
  cursor: pointer;
}
.prompt_box_gear {
  rotate: 90deg;
  color: var(--accent-blue);
  transition: var(--all-transition);
}
.prompt_box:hover .prompt_box_gear {
  color: white;
}
.prompt_box:hover {
  background: var(--accent-blue);
}

.header_imgs {
  width: 45%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  align-self: flex-end;
}
.header_row_imgs1 {
  display: flex;
  flex-direction: column;
}
.header_row_imgs1 img {
  width: 100px;
}

/* -------------------swiper------------------ */
.header_imgs .swiper {
  width: 300px;
  height: 100vh;
}

.header_imgs .swiper-slide {
  height: 100px;
  text-align: center;
  font-size: 18px;
  background: none;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header_imgs .swiper-slide img {
  display: block;
  width: 250px;
  height: 440px;
  object-fit: cover;
}

/* -------------how it work------------ */

.howItwork {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 123px 15px 112px 15px;
  flex-direction: column;
}

.heading_warp {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: 500px; */
  align-items: center;
}
.spanhead {
  font-weight: 700;
  font-size: 18px;
}
.uni_heading {
  color: black;
  font-size: 52px;
  font-weight: 700;
  max-width: 960px;
}
._heading {
  color: black;
  font-size: 48px;
  font-weight: 700;
}
.highlighted_text {
  line-height: 1.1em;
  font-family: "Playfair Display", Sans-serif;
  color: var(--accent-blue);
  font-size: 48px;
  font-style: italic;
  font-weight: bold;
  /* margin-left: 10px; */
}

.howit_main {
  display: flex;
  /* gap: 10px; */
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;
  gap: 100px;
}
.howit_imgs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.howit_imgs_rows {
  display: flex;
  gap: 10px;
}
.howit_imgs_rows img {
  width: 200px;
  object-fit: cover;
}

.howit_imgs_bottom {
  height: 475px;
  object-fit: cover;
  object-position: top;
}

.lists_boxs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list_box {
  background: #f2f8fd;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #3468f036;
  padding: 25px 50px 26px 70px;
  transition: var(--all-transition);
  position: relative;
  border-radius: 25px;
}
.list_box_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.list_box_content h3 {
  font-size: 24px;
  color: black;
  font-weight: 700;
}
.list_box_content p {
  line-height: 26px;
}
.list_box_no {
  position: absolute;
  top: 25%;
  left: 2%;
  margin: 9px 0px 0px -40px;
  padding: 9px 21px 8px 21px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #3468f038;
  border-radius: 12px 12px 12px 12px;
  font-size: 24px;
  color: var(--accent-blue);
  font-weight: 700;
  transition: var(--all-transition);
}
.list_box:hover {
  background: white;
  filter: drop-shadow(0px 0px 20px #3469f041);
  border-color: #3468f0;
}
.list_box:hover .list_box_no {
  color: #ffffff;
  background: var(--accent-blue);
}

.welcome_sec {
  background: var(--background-blue);
  padding: 132px 15px 130px 15px;
}

.welcome_sec_warp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 90px;
}
.welcome_imges {
  background: rgba(37, 149, 190, 0.005);
  box-shadow: 0px 10px 160px 0px
    rgba(51.99999999999996, 104.00000000000004, 239.99999999999991, 0.13);
  width: 45%;
  padding: 30px 30px 38px 28px;
  border-radius: 25px;
  border: 2px solid #3468f02e;
  /* filter: drop-shadow(0px 0px 20px #3469f00f); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.welcome_img_row1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}
.welcome_img_row2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.welcome_img_top {
  width: 270px;
  animation: moveup 5s ease-in-out infinite alternate;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px;
}
.welcome_img_bottom {
  width: 200px;
  height: 165px;
  animation: moveleft 5s ease-in-out infinite alternate;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px;
}
.welcome_img_top_right {
  width: 188px;
  height: 153px;
  animation: moveright 5s ease-in-out infinite alternate;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px;
}
.welcome_img_bottom_right {
  padding: 90px 10px 30px 20px;
  background: #ff9900;
  border-radius: 25px;
  font-size: 24px;
  color: white;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  /* margin-bottom: 10px; */
  /* padding-bottom: 10px; */
  width: 85%;
}
.welcome_img_bottom_right img {
  width: 170px;
  height: 30px;
  padding-left: 20px;
  /* margin-top: 10px; */
}

.abs_img {
  position: absolute;
  top: 25%;
  right: 27%;
  animation: moveup 6s ease-in-out infinite alternate;
}
.hand_img {
  position: absolute;
  top: 40%;
  right: 25%;
}
.welcome_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.welcome_content .heading_warp {
  text-align: left;
}
.lists_checks {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.list_item_check {
  display: flex;
  align-items: center;
  gap: 15px;
}
.blue {
  color: var(--accent-blue);
  /* background: var(--accent-blue); */
}

.WhatProvide_sec {
  padding: 123px 15px 122px 15px;
}
.WhatProvide_box {
  display: flex;
  align-items: center;
  margin-top: 75px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.WhatProvide_content {
  width: 43%;
}
.WhatProvide_img_box {
  padding: 30px;
  border: 1px solid #3468f029;
  background: #f2f8fd;
  border-radius: 25px;
}
.WhatProvide_img_box img {
  width: 600px;
  height: 450px;
  overflow: hidden;
  border-radius: 15px;
  object-position: center;
  object-fit: cover;
}

.plan_warp {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}
.basic_plan {
  background: #f2f8fd;
  border-radius: 25px;
  border-width: 1px 1px 1px 1px;
  border-color: #3468f02e;
  border-style: solid;
  margin: 0px 15px 0px 0px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 0px;
  /* padding: 41px 53px 10px 50px; */
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
}
.Standard_plan {
  background: #0d111a;
  border-radius: 25px;
  border-width: 1px 1px 1px 1px;
  border-color: #3468f02e;
  border-style: solid;
  margin: 0px 15px 0px 0px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 0px;
  /* padding: 41px 53px 10px 50px; */
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
  color: white;
}
.Standard_plan h4 {
  font-size: 27px;
  line-height: 1.33em;
  font-weight: 700;
  /* color: black; */
}
.basic_plan h4 {
  font-size: 27px;
  line-height: 1.33em;
  font-weight: 700;
  color: black;
}
.green {
  color: #cbed44 !important;
  font-weight: 700;
  font-size: 20px;
}
.basic_plan span {
  font-weight: 700;
  font-size: 20px;
}
.Standard_plan b {
  color: white;
  font-family: "Playfair Display", Sans-serif;
  font-size: 20px;
}
.basic_plan b {
  color: #030014;
  font-family: "Playfair Display", Sans-serif;
  font-size: 20px;
}
.plan_sec_heading {
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}
.plan_ctr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.plan_price {
  font-size: 48px;
  font-family: "Playfair Display", Sans-serif;
  color: black;
  font-weight: 700;
  font-style: italic;
}
.plan_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-colors);
  padding: 15px;
  border-radius: 15px;
  font-weight: 700;
  gap: 5px;
  background: white;
  color: black;
  transition: var(--all-transition);
}
.plan_btn:hover {
  background: var(--accent-blue);
  color: white;
}
.blue_btn {
  background: var(--accent-blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-colors);
  padding: 15px;
  border-radius: 15px;
  font-weight: 700;
  transition: var(--all-transition);
  gap: 5px;
}
.blue_btn:hover {
  color: black;
  background: white;
}
.Packages_section {
  padding: 0px 15px 130px 15px;
}

.Blogs_sec {
  background: var(--background-blue);
  padding: 123px 15px 108px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Blog {
  display: flex;
  flex-direction: column;
  width: 350px;
  /* height: 475px; */
  padding: 28px;
  padding-bottom: 23px;
  border: 1px solid var(--border-colors);
  border-radius: 25px;
  gap: 15px;
  transition: var(--all-transition);
}
.hover_blog_img {
  transition: var(--all-transition);
}
.Blog:hover .hover_blog_img {
  position: absolute;
  width: 350px;
  height: 250px;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  border-radius: 15px;
  overflow: hidden;
}
._blog_img_warp {
  position: relative;
}

.Blog img {
  width: 350px;
  height: 250px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px;
  object-position: top;
}
.blog_contnet {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #696969;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.276em;
}
.blog_contnet span {
  /* font-size: 19px; */
  background: rgba(0, 0, 255, 0.147);
  border-radius: 50%;
  overflow: hidden;
  height: 5px;
  width: 5px;
}
.blog_heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: black;
  margin-bottom: 32px;
  transition: var(--all-transition);
}
.blog_heading:hover {
  color: var(--accent-blue);
}
.read_more {
  /* border: 1px solid var(--border-colors); */
  border-top: 1px solid var(--border-colors);
  padding-top: 20px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: var(--all-transition);
}
.read_more:hover {
  color: var(--accent-blue);
}
.Blogs-sec_warp {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  overflow: hidden;
}

.Main_footer_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Main_footer {
  background: #f2f8fd;
  background-image: url(./Assets/Line-1-12.webp);
  min-height: 450px;
}
.main_footer_text {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.main_footer_text .logo {
  font-size: 54px;
}
.footer_mail {
  padding: 20px 15px;
  background: white;
  font-size: 18px;
  border: 1px solid var(--border-colors);
  /* width: 225px; */
  max-width: 250px;
  color: #6ec1e4;
  font-weight: 600;
  text-align: right;
  border-radius: 15px;
}

.main_footer_letter {
  display: flex;
  flex-direction: column;
}
.main_footer_letter {
  background: white;
  padding: 50px;
  border-radius: 25px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* border: 1px solid var(--border-colors); */
}
.letter_p {
  color: #030014;
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.5em;
  letter-spacing: -0.03em;
}
.letter_p span {
  font-family: "Playfair Display", Sans-serif;
  font-style: italic;
  color: var(--accent-blue);
  font-weight: 700;
}
.newsletter_email {
  display: flex;
  align-items: center;
  gap: 20px;
}
.newsletter_email input {
  background: var(--background-blue);
  border: 1px solid var(--accent-blue);
  outline: none;
  padding: 12px 20px;
  border-radius: 10px;
  width: 100%;
  /* color: black !important; */
}
.newsletter_email input::placeholder {
  color: black;
}
.news_btn {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  outline: none;
  border: none;
  background: var(--accent-blue);
  color: white;
  gap: 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
}
.copyright {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  border-top: 1px solid #3469f03c;
}
.ul_menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gallery_header {
  height: 80vh;
  background: url(./Assets/opacity\ 2.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* filter: blur(5px); */
}
.gallery_header_content {
  display: flex;
  /* align-items: center;
  text-align: center; */
  justify-content: center;
  flex-direction: column;
  color: white;
  position: relative;
  height: 80vh;
  z-index: 100;
  width: 50%;
  margin: 0 auto;
  gap: 20px;
}
.gallery_header_content h2{
  /* width: 29px; */
  font-size: 65px;
}
.post_img_info .blue_btn{
  width: fit-content;
}
.search_p{
  font-size: 18px;
}
.header_cover {
  height: 80vh;
  background: #00000072;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  /* filter: blur(5px); */
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  /* margin-bottom: 10px; */
  break-inside: avoid;
  transition: var(--all-transition);
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
  width: 100%;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  /* padding: .2em .5em; */
  justify-self: start;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  font-size: 12px;
  padding: 12px 16px;
  width: 91%;
  overflow: hidden;
  transition: var(--all-transition);
}
.img_bio {
  width: 24px;
  border-radius: 50%;
  overflow: hidden;
}
figure:hover figcaption {
  height: 100px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%); */
}
.images_bio {
  display: flex;
  /* align-items: center; */
  width: 100%;
  gap: 20px;
  flex-direction: column;
}
.container2 {
  column-count: 4;
  /* column-gap: 10px; */
  gap: 0;
}
.Search_meta {
  font-size: 1rem;
}
.img_tags {
  display: none;
  transition: var(--all-transition);
}
figure:hover .img_tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.img_tag {
  padding: 2px 5px;
  background: #3469f07f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_porfile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Search_box {
  display: flex;
  align-items: center;
  gap: 0px;
  width: 90%;
  justify-content: space-between;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  padding: 7px;
}
.Search_box input {
  width: 80%;
  font-size: 15px;
  padding: 7px;
  border: none;
  outline: none;
  font-size: 12px;
}

.Search_box button {
  background: var(--accent-blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--border-colors);
  /* padding: 15px; */
  padding: 10px;
  border-radius: 15px;
  font-weight: 700;
  transition: var(--all-transition);
  gap: 5px;
  width: 100px;
}
.Search_box button:hover {
  color: black;
  background: white;
}

.single_img_warp {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 150px;
  padding-bottom: 200px;
}
.post_img_p {
  /* padding: 20px; */
  width: 635px;
  /* max-height: 1100px; */
  object-fit: cover;
}
.images_bio_page {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.img_info_tags {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  overflow: hidden;
  border-radius: 5px;
}
.img_tags_single {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.img_tag_single {
  background: var(--accent-blue);
  color: white;
  padding: 5px;
  border-radius: 7px;
}
.img_more-Info {
  display: flex;
  align-items: center;
  gap: 15px;
}
.img_more-Info a {
  border: 1px solid black;
  color: black;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.img_info_boxs{
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  gap: 20px;
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  overflow: hidden;
  border-radius: 5px;
}
.img_info_box{
  display: flex;
  align-items: center;
  font-size: .875em !important;
  gap: 5px;
  color: black;
}
.sm_btn{
  padding: 4px;
  border-radius: 5px;
}



.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.tag-category {
  margin: 10px;
}

.tag-button {
  padding: 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.tag-button.selected {
  background-color: rgb(14 159 110) !important ;
  color: white;
}

.selected-tags {
  margin: 20px 0;
}

.selected-tag {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}
.infoimg_label{
  font-size: .75rem;
  font-weight: 600;
  color: rgb(107, 114, 128);
  font-weight: bolder;
  text-transform: uppercase;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.generation_page{
  /* background: red; */
  padding-top: 80px;
  position: relative;
}
.Generate_page_warp_b{
  background: #f4f5f7;
  min-height: 100vh;

}

.generation_page_warp{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: auto;
  position: relative;
}
.generation_tags{
  width: 30%;
  padding: 1rem;
  border-right: 1px solid #dee2e6 !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* overflow-y: scroll; */
  padding-top: 40px;
  background: white;
  box-shadow: 10px 0px 5px 0px rgba(9, 152, 255, 0.02);

}
.generation_tags::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  background-color: white; /* Background color of the scrollbar */
}
.generation_tags::-webkit-scrollbar-thumb {
  background-color: var(--border-colors); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}
.propt_warn{
  font-size: 14px;
}
.generation_inp{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.generation_inp label{
  font-weight: 500;
  color: #030014;
}
.generation_inp label b{
  color: var(--accent-blue);
}

.generation_page_warp select{
  display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* color: #495057; */
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 3px;
    border: 2px;
    border-color: transparent;
    resize: none;
    background-color: white;
    color: #030014;
    outline: none;
    border: 1px solid rgba(0, 0, 0, .125);
}





.generated_area{
  width: 60%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  gap: 10px;
  /* overflow-y: scroll !important; */
  padding-top: 35px;
  padding-bottom: 40px;
}
.main_rendered_img{
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.main_rendered_img img{
  max-width: 740px !important;
    object-fit: cover;
    object-position: top;
}
.generated_area::-webkit-scrollbar{
  width: 0px;
}
.placeholder_icon{
  margin-top: 200px;
  color: rgb(108, 117, 125) !important;
}

.loader_warp{
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}
.loader {
  width: 44.8px;
  height: 44.8px;
  color: var(--accent-blue);
  position: relative;
  background: radial-gradient(11.2px,currentColor 94%,#0000);
}

.loader:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(10.08px at bottom right,#0000 94%,currentColor) top    left,
          radial-gradient(10.08px at bottom left ,#0000 94%,currentColor) top    right,
          radial-gradient(10.08px at top    right,#0000 94%,currentColor) bottom left,
          radial-gradient(10.08px at top    left ,#0000 94%,currentColor) bottom right;
  background-size: 22.4px 22.4px;
  background-repeat: no-repeat;
  animation: loader 1.5s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes loader {
  33% {
    inset: -11.2px;
    transform: rotate(0deg);
  }

  66% {
    inset: -11.2px;
    transform: rotate(90deg);
  }

  100% {
    inset: 0;
    transform: rotate(90deg);
  }
}












.generated_details{
  width: 20%;
  border-left: 1px solid #dee2e6 !important;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* overflow-y: auto; */
  /* height: 86vh; */
  overflow-y: scroll !important;
  padding-top: 40px;
  background: white;
  box-shadow: -10px 0px 5px 0px rgba(9, 152, 255, 0.02);

  
}
.generated_details::-webkit-scrollbar {
  width: 0px; 
  background-color: white; 
}
.generated_details::-webkit-scrollbar-thumb {
  background-color: var(--border-colors);
  border-radius: 10px; 
}
.generation_inp input{
  color: rgb(107, 114, 128);
    font-size: .75rem;
    font-weight: 600;
    font-weight: bolder;
    text-transform: uppercase;
}
.input_main{
  display: block;
  /* width: 100%; */
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border-radius: 3px;
  border: 2px;
  border-color: transparent;
  resize: none;
  background-color:white;
  color: #6c757d;
  outline: none;
  border: 1px solid rgba(0, 0, 0, .125);
}
.prompt_area{
  width: 100%;
  height: 180px;
  resize: none;
  border: 1px solid rgba(0, 0, 0, .125);
  outline: none;
  padding: 5px;
  color: #6c757d;
  overflow-y: scroll;
}
.prompt_area::-webkit-scrollbar{
  width: 0;
}
.image_generate_btn{
  background: var(--accent-blue);
  outline: none;
  border: none;
  color: white;
  padding: 20px 10px;
  font-size: 17px;
  border-radius: 15px;
  text-transform: capitalize;
  /* justify-self: flex-end; */
  margin-top: 50px;
  cursor: pointer;
}
.go_pro_text{
  font-size: 12px;
  color: black;
  text-align: center;
}
.go_pro_text:hover{
  text-decoration: underline;
}
.gallary_page_warp{
  overflow-x: hidden;
}


.about_cards_box{
  padding-top: 100px;
  padding-bottom: 100px;
}

.about_cards{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  /* flex-wrap: wrap; */
}
.about_card_i{
  font-size: 39px;
  color: var(--accent-blue);
}
.about_card{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  padding: 30px;
  width: 250px;
  height: 260px;
  transition: var(--all-transition);
}
.about_card:hover{
  outline: 1px solid var(--accent-blue);
  cursor: text;
}

.about_card span{
  font-family: "Space Grotesk", Sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.5em;
    letter-spacing: -0.03em;
    color: #030014;
    transition: var(--all-transition);
}
.about_card span:hover{
  color: var(--accent-blue);
}
.oder_in{
  position: absolute;
  top: 30px;
  right: 53px;
  font-size: 100px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -3px;
  background: linear-gradient(180deg, rgba(230, 228, 252, 0.50) 0%, rgba(230, 228, 252, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about_card_btn{
  color: #62647A;
  font-weight: 600;
}
.contact_card{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  padding: 30px;
  width: 250px;
  /* height: 260px; */
  align-items: center;
  transition: var(--all-transition);
  transition: all 0.5s ease-in-out;
}
.contact_card:hover{
  outline: 1px solid var(--accent-blue);
}
.card_i{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  /* border: 1px solid brown; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_form_warp{
  padding-top: 10px;
  padding-bottom: 100px;
}
.contact_form{
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  background: #EBEBFD;
  width: fit-content;
  outline: 1px solid #3469f053;
  width: 60%;
  margin-top: 40px;
  border-radius: 15px;
}
.contact_form input{
  padding: 16px 23px 12px 23px;
  border-radius: 10px;
  border: none;
  outline: none;
  width: 100%;
}
.contact_form textarea{
  
  /* padding: 16px 23px 12px 23px; */
  /* padding-top: 16px;
  padding-bottom: 16px; */
  padding: 16px;
  border-radius: 10px;
  border: none;
  outline: none;
  width: 96%;
  min-height: 100px;
  max-width: 100%;
  resize: none;
}
.input_layer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.meass_btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected_tags {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-transform: capitalize;
}
.tags_section_wrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tags_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  /* background-color: #ddd; */
  /* border: 1px solid var(--accent-blue); */
  text-transform: capitalize;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  /* text-decoration: underline; */
  background: var(--accent-blue);
  color: white;

}

.tag.selected {
  background: green;
  color: white;
}
.selected:hover{
  background-color: red;
  cursor:pointer;
}
.selected:hover .remove_tag{
  display: flex;
}
.selected{
  position: relative;
}
.remove_tag{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FC4100;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.tag_category {
  margin-bottom: 5px;
}
.category_names{
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}
.category_names h5{
  /* background: var(--accent-blue);
  color: white; */
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 10px 0px 5px 0px rgba(9, 152, 255, 0.03);

  /* text-decoration: underline; */
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--accent-blue);
  transition: var(--all-transition);
}
.category_names h5:hover{
  background: var(--background-blue);
  /* color: white; */
}








.min_gallery_box .swiper {
  width:97vw;
  height: 400px;
}

.min_gallery_box .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 400px;
  width: 400px;
  object-fit: cover;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.min_gallery_box .swiper-slide img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  overflow: hidden;
}
.Pricingblock_warp{
  padding-top: 100px;
  padding-bottom: 100px;
}
.p_btn{
  display: none;
}

.generated_images{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}
.generated_image img{
  max-width: 600px;
}
.generated_image{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  /* display: inline-block; */
}
.watermark {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 19px;
  font-family: Arial, sans-serif;
}
/* App.css or src/components/TermsPopup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the popup is on top */
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* text-align: center; */
  width: 40%;
}

.popup button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin-left: 0;
}
.popup ol{
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popup button:hover {
  background-color: #0056b3;
}

.blur {
  filter: blur(20px);
  pointer-events: none; /* Prevent interactions with blurred content */
}
.popup{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  gap: 30px;
}
.popup h2{
  border-bottom: 1px solid lightgray;
  width: 100%;
  padding-bottom: 10px;
}
.warning_p{
  color: red;
}

.popups_btn{
  border-top: 1px solid lightgray;
  width: 100%;
}
@media (max-width: 1024px) {
  .howit_imgs_rows{
    justify-content: space-between;
  }
  .popup-overlay{
    height: 100vh;
    align-items: flex-start;

  }
  .popup{
    margin-top: 50px;
    width: 80%;
  }
  .placeholder_icon{
    margin-top: 0;
  }
  .loader_warp{
    margin-top: 0;
  }
  .generated_image img{
    max-width: 500px;
  }
  .container2 {
    column-count: 3;
  }
  .gallery_header_content{
    width: 90%;
  }
  nav{
    background: white;
    backdrop-filter: blur(0px);
    filter: blur(0px);
  }
  .menu_nav ul {
    display: none;
    flex-direction: column;
    gap: 0;
  }
  .menu_nav.open ul {
    display: flex;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 1);
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
  }
  .menu_nav.open .menu_btn_mobile {
    display: flex;
  }
  .hamburger {
    display: block;
  }
  .about_cards{
    justify-content: center;
    flex-wrap: wrap;
  }



.Generate_page_warp_b{
  max-height: 100%;
}
.generation_page_warp{
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  width: 95%;
  margin: 0 auto;
}
.generation_page_warp select{
  width: 95%;
}
.generation_page form{
  display: flex;
  align-items: center;
  justify-content: center;
}
.generated_area{
  order: -1;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.prompt_area{
  width: 91%;
}
.image_generate_btn{
  width: 95%;
}
.generation_inp input{
  width: 90%;
}
.generation_tags{
  width: 95%;
  padding: 10px;
  overflow:hidden;
  border: none;
}
.generated_details{
  width: 95%;
  padding: 10px;
  overflow: hidden;
  border: none;
}
.howit_main{
  flex-wrap: wrap;
  justify-content: center;
}
.welcome_sec_warp{
  flex-wrap: wrap;
  justify-content: center;
}
.welcome_imges{
  width: 60%;
}
.WhatProvide_box{
  justify-content: center;
  gap: 30px;
}
}

@media only screen and (max-width: 768px) {
  .generated_image img{
    max-width: 300px;
  }
  .single_img_warp{
    flex-direction: column-reverse;
    padding-top: 110px;
  }
  .post_img_p{
    width: 350px;
  }
  .images_bio_page{
    width: 100%;
  }
  .p_btn{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .min_gallery_box{
    display: none;
  }
  
  .min_gallery_box .swiper {
    width:94vw;
    height: 250px;
  }
  
  .min_gallery_box .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 400px;
    width: 400px;
    object-fit: cover;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .min_gallery_box .swiper-slide img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .contact_form{
    padding: 10px;
    width: 90%;
  }
  .contact_form textarea {
    width: 90%;
  }
  
  .container2 {
    column-count: 1;
  }
  

  .container {
    width: 95%;
  }
  .header_text {
    margin-top: 100px;
  }
  .header {
    max-height: none;
    height: fit-content;
    background-position: center;
    background-size: cover;
  }
  .header_flex {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .header_text {
    width: 90%;
    /* margin-bottom: 40px; */
  }
  .header_imgs {
    width: 100%;
    /* width: 160px; height: 360px; */
  }
  .text_heading {
    font-size: 36px;
  }
  .italc_heading {
    font-size: 36px;
  }
  .input_prompt .menu_btn {
    /* padding: 10px; */
    font-size: 15px;
  }
  .m_btn{
    display: none;
  }
  .input_prompt input {
    width: 100px;
  }
  .prompt_box {
    width: 60px;
    height: 60px;
  }
  .prompt_warp {
    gap: 5px;
  }
  .swiper-slide img {
    width: 160px;
    height: 330px;
  }
  .howit_main {
    flex-wrap: wrap;
    gap: 20px;
  }
  .howit_imgs_rows img {
    width: 105px;
  }
  .howit_imgs_bottom {
    width: 100%;
  }
  .welcome_sec_warp {
    flex-wrap: wrap;
  }
  .welcome_img_top {
    width: 100%;
  }
  .welcome_imges {
    flex-wrap: wrap;
    width: 100%;
  }
  .abs_img {
    display: none;
  }
  .hand_img {
    display: none;
  }
  .WhatProvide_box {
    flex-wrap: wrap;
  }
  .WhatProvide_content {
    width: 100%;
  }
  .WhatProvide_img_box {
    width: 100%;
    padding: 10px;
  }
  .WhatProvide_img_box img {
    width: 90%;
  }
  .plan_warp {
    flex-wrap: wrap;
  }
  .basic_plan {
    width: 90%;
    padding: 20px;
  }
  .Standard_plan {
    width: 90%;
    padding: 20px;
  }
  .plan_price {
    /* padding: 30px; */
    font-size: 30px;
  }
  .uni_heading {
    font-size: 29px;
    text-align: center;
  }
  .Blogs-sec_warp {
    flex-wrap: wrap;
    gap: 20px;
  }
  .Blog {
    width: 90%;
    padding: 10px;
    gap: 20px;
  }
  .Main_footer_content {
    flex-wrap: wrap;
  }
  .Blog img {
    width: 300px;
  }
  .WhatProvide_img_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  .WhatProvide_box {
    gap: 30px;
  }
  .list_box {
    padding: 30px;
  }
  .list_box_no {
    padding: 7px 15px 7px 15px;
    left: 6%;
  }
  .main_footer_letter {
    width: 100%;
    padding: 24px;
  }
  .copyright {
    flex-wrap: wrap;
    gap: 20px;
  }
  
}

@keyframes moveup {
  0% {
    transform: translateY(0); /* Start at top position */
  }
  50% {
    transform: translateY(-10px); /* Move down 50px */
  }
  100% {
    transform: translateY(0); /* Return to top position */
  }
}
@keyframes moveleft {
  0% {
    transform: translateY(0); /* Start at top position */
  }
  50% {
    transform: translatex(-30px); /* Move down 50px */
  }
  100% {
    transform: translateY(0); /* Return to top position */
  }
}
@keyframes moveright {
  0% {
    transform: translateY(0); /* Start at top position */
  }
  50% {
    transform: translatex(30px); /* Move down 50px */
  }
  100% {
    transform: translateY(0); /* Return to top position */
  }
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}



